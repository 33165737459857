body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ----------------------------------------------------------------------- */
/* ----------------------------------------------------------------------- */
/* ----------------------------------------------------------------------- */
/* ----------------------------------------------------------------------- */
/* ----------------------------------------------------------------------- */

@keyframes anim1 {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  50% {
    transform: translate3d(-30%, 0, 0);
  }
  75% {
    transform: translate3d(-15%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes anim2 {
  0% {
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

.appIcon {
  width: 180px;
  height: 50px;
  margin-left: -12px;
  background-image: url(./assets/app.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 24px;
}

.soicialIcon {
  display: flex;
  align-items: center;
}
.soicialIcon_A {
  width: 26px;
  height: 26px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 24px;
  cursor: pointer;
}
.soicialIcon_A.fb {
  background-image: url(./assets/fb.svg);
}
.soicialIcon_A.twitter {
  background-image: url(./assets/twitter.svg);
}
.soicialIcon_A.insta {
  background-image: url(./assets/insta.svg);
}
.soicialIcon_A.whatsapp {
  background-image: url(./assets/whatsapp.svg);
}
.soicialIcon_A.linkedin {
  background-image: url(./assets/linkedin.svg);
}
.soicialIcon_A.youtube {
  background-image: url(./assets/youtube.svg);
}

/* ----------------------------------------------------------------------- */
/* ----------------------------------------------------------------------- */
/* ----------------------------------------------------------------------- */
/* ----------------------------------------------------------------------- */
/* ----------------------------------------------------------------------- */
.a {
  width: 100%;
}
.aA {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;
  background-color: white;
}
.aAa {
  display: flex;
  align-items: center;
}

.aAaB {
  font-size: 16px;
  color: #4c4e64;
  padding: 12px;
  cursor: pointer;
}
.aAb {
  width: 30px;
  height: 30px;
  background-image: url(./assets/more.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  position: relative;
  display: none;
}
.aAbA {
  position: absolute;
  top: 35px;
  right: 0;
  width: 100px;
  background-color: white;
  box-shadow: 0 0 3px #4c4e6421;
  padding: 8px;
}
.aAbAa {
  font-size: 16px;
  padding: 8px;
  color: #4c4e64;
  cursor: pointer;
}
@media only screen and (max-width: 1000px) {
  .aAb {
    display: flex;
  }
}
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
.aB {
  height: calc(100vh - 80px);
  background-image: url(./assets/cover.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  padding: 10%;
}
.aBa {
  max-width: 600px;
  font-size: 48px;
  color: #4c4e64;
  animation: anim1 0.8s linear;
  margin-right: 20%;
}
.aBa span {
  color: #5956e9;
  font-weight: 700;
}
.aBb {
  max-width: 600px;
  color: #4c4e64dd;
  font-size: 20;
  margin: 25px 0;
  font-weight: 300;
  margin-right: 20%;
}
.aBcA {
  display: flex;
}
.aBcAa {
  margin-right: 24px;
  background-color: white;
  border-radius: 8px;
  display: flex;
  color: #4c4e64dd;
  padding: 16px 24px;
  font-weight: 600;
  font-size: 14px;
  animation: anim1 0.8s linear;
  cursor: pointer;
}
.aBcAa .home {
  width: 16px;
  height: 16px;
  background-image: url(./assets/home.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 8px;
}
.aBcAa .glob {
  width: 16px;
  height: 16px;
  background-image: url(./assets/glob.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-right: 8px;
}
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
.aC {
  display: flex;
  /* overflow: auto; */
  padding: 0 5%;
  padding-top: 10px;
}
.aCa {
  min-width: 130px;
  width: 18vw;
  height: 60px;
  margin: 38px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 8px;
}
.aCa.un1 {
  background-image: url(./assets/univercity/1.png);
}
.aCa.un2 {
  background-image: url(./assets/univercity/2.png);
}
.aCa.un3 {
  background-image: url(./assets/univercity/3.png);
}
.aCa.un4 {
  background-image: url(./assets/univercity/4.png);
}
.aCa.un5 {
  background-image: url(./assets/univercity/5.png);
}
.aCa.un6 {
  background-image: url(./assets/univercity/6.png);
}
.aCa.un7 {
  background-image: url(./assets/univercity/7.png);
}
.aCa.un8 {
  background-image: url(./assets/univercity/8.png);
}
.aCa.un9 {
  background-image: url(./assets/univercity/9.png);
}
.aCa.un10 {
  background-image: url(./assets/univercity/10.png);
}
.aCa.un11 {
  background-image: url(./assets/univercity/11.png);
}
.aCa.un12 {
  background-image: url(./assets/univercity/12.png);
}
.aCa.un13 {
  background-image: url(./assets/univercity/13.png);
}
.aCa.un14 {
  background-image: url(./assets/univercity/14.png);
}
.aCa.un15 {
  background-image: url(./assets/univercity/15.png);
}
.aCa.un16 {
  background-image: url(./assets/univercity/6.png);
}
.aCa.un17 {
  background-image: url(./assets/univercity/7.png);
}
.aCa.un18 {
  background-image: url(./assets/univercity/8.png);
}
.aCa.un19 {
  background-image: url(./assets/univercity/9.png);
}
.aCa.un20 {
  background-image: url(./assets/univercity/10.png);
}
.aCa.un21 {
  background-image: url(./assets/univercity/11.png);
}
.aCa.un22 {
  background-image: url(./assets/univercity/12.png);
}
.aCa.un23 {
  background-image: url(./assets/univercity/13.png);
}
.aCa.un24 {
  background-image: url(./assets/univercity/14.png);
}
.aCa.un25 {
  background-image: url(./assets/univercity/15.png);
}
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
.aD {
  margin-top: -40px;
  padding: 68px 10%;
  background-color: #5856e91e;
}
.aDa {
  background-color: white;
  border-radius: 8px;
  padding: 68px;
  display: flex;
  justify-content: space-between;
}
.aDaA {
  font-size: 16px;
  color: #4c4e64;
  line-height: 25px;
}
.aDaA div {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 20px;
}
.aDaB {
  min-width: 600px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}
.aDaBa {
  width: 260px;
  border-radius: 10px;
  border: 1px solid #e1e4ed;
  padding: 30px;
  box-sizing: border-box;
  text-align: center;
  color: #4c4e64;
  margin-left: 21px;
  background-color: transparent;
  transition: background-color 0.8s ease, color 0.3s ease;
}
.aDaBa:hover {
  background-color: #5956e9;
  color: white;
}
.aDaBaA {
  font-size: 38px;
  font-weight: 600;
}
.aDaBaB {
  margin-top: 8px;
  font-weight: 700;
  font-size: 16px;
}
.aDaBaC {
  margin-top: 8px;
}
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
.aE {
  padding: 68px 0;
  background-color: white;
}
.aEa {
  font-size: 38px;
  font-weight: bold;
  color: #4c4e64;
  text-align: center;
}
.aEb {
  text-align: center;
  color: #4c4e64;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 40px;
}
.aEc {
  display: flex;
  overflow: auto;
  padding: 0 10%;
}
.aEcA {
  border: 1px solid #e1e4ed;
  min-width: 240px;
  padding: 32px 26px;
  color: #4c4e64;
  border-radius: 8px;
  margin-right: 22px;
  transition: background-color 0.8s ease, color 0.3s ease;
}
.aEcA:hover {
  background-color: #5956e9;
  color: white;
}
.aEcAa {
  font-size: 19px;
  font-weight: bold;
}
.aEcAb {
  font-size: 16px;
  margin: 10px 0;
}
.aEcAc {
  margin-top: 18px;
  padding-top: 18px;
  border-top: 1px solid #e1e4ed;
  font-size: 30px;
  font-weight: bold;
}
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
.aF {
  padding: 68px 10%;
  background-color: #5856e91e;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.aFa {
  font-size: 42px;
  font-weight: bold;
  color: #4c4e64;
  text-align: center;
  line-height: 42px;
  max-width: 550px;
  width: 100%;
}
.aFb {
  max-width: 550px;
  width: 100%;
  text-align: center;
  color: #4c4e64;
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 40px;
}

.aFc {
  max-width: 550px;
  width: 100%;
  background-color: white;
  padding: 32px;
  border-radius: 8px;
  border: 1px solid #e1e4ed;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 600;
  color: #4c4e64;
}
.aFcA {
  width: 46%;
  padding-bottom: 22px;
}
.aFcB {
  width: 100%;
  margin-top: 8px;
}
.aFc input {
  width: 100%;
  margin-top: 10px;
  padding: 12px 18px;
  box-sizing: border-box;
  border: 1px solid #e1e4ed;
  border-radius: 8px;
  box-shadow: 0 0 3px #4c4e6421;
}
.aFc select {
  width: 100%;
  margin-top: 10px;
  padding: 12px 18px;
  box-sizing: border-box;
  border: 1px solid #e1e4ed;
  border-radius: 8px;
  box-shadow: 0 0 3px #4c4e6421;
}
.aFc textarea {
  border: 1px solid #e1e4ed;
  width: 100%;
  margin-top: 10px;
  padding: 12px 18px;
  box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 0 3px #4c4e6421;
}
.aFc button {
  border: none;
  background-color: #5d5fef;
  padding: 12px 18px;
  color: white;
  font-weight: bold;
  margin-top: 20px;
  border-radius: 6px;
  cursor: pointer;
}
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
/* //////////////////////////////////////////////////////////////////// */
.aG {
  padding: 0 10%;
}
.aG_A {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 72px 0;
}
.aG_Aa {
  width: 300px;
}
.aG_AaB {
  font-size: 16px;
  color: #4c4e64;
  padding: 20px 0;
}
.aG_Ab {
  width: 300px;
  color: #4c4e64;
  font-size: 16px;
  line-height: 32px;
}
.aG_AbA {
  font-weight: 600;
}
.aG_AbB {
  font-weight: 400;
  color: #4c4e64;
}
.aG_AbB div {
  line-height: 26px;
}
.aG_B {
  padding: 22px;
  color: #4c4e6467;
  border-top: 1px solid #e1e4ed;
  text-align: center;
}
.aG_B span {
  color: #4c4e64;
}

@media only screen and (max-width: 1000px) {
  .aAaB {
    display: none;
  }
  .aA .soicialIcon {
    display: none;
  }
  .aBa {
    font-size: 32px;
  }
  .aDa {
    flex-direction: column;
    padding: 38px;
  }
  .aDaB {
    min-width: auto;
    justify-content: start;
  }
  .aDaBa {
    padding: 20px;
    width: calc(100vw - 60px);
    margin: 0 0 0 0;
    margin-top: 12px;
  }
}
